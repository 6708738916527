import { graphql } from "gatsby";
import React from "react";
import FaqPage from "../../components/pages/assistenza-clienti";

const AssistenzaClienti = (props) => <FaqPage {...props} />;

export const query = graphql`
  query FaqQueryEn {
    storyblokEntry(lang: { eq: "en" }, slug: { eq: "faqs-sottocategorie" }) {
      content
    }
    allStoryblokEntry(
      filter: { lang: { eq: "en" }, field_component: { eq: "faq" } }
    ) {
      nodes {
        slug
        name
        lang
        field_component
        tag_list
        content
      }
    }
  }
`;

export default AssistenzaClienti;
